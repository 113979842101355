* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html {
    box-sizing: border-box;
}
#root{
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
}
ul, ol {
    list-style: none;
}
body, h1, h2, h3, h4, h5, h6, p, blockquote, pre, dl, dd, figure, figcaption, hr, fieldset, legend, textarea, menu, dir {
    margin: 0;
}
fieldset, blockquote, figure, ol, ul, dl, dd {
    padding: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: inherit;
}


input, select, textarea, button {
    font: inherit;
    margin: 0;
}


a {
    text-decoration: none;
    color: inherit;
}

:focus {
    outline: none;
}
