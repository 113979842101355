@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200..1000&display=swap');
* {
  font-family: "Nunito" !important;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 14px;
}
body {
  margin: 0;
  background-color: #f6f6f6 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.table-responsive{
  max-height: 70vh!important;
}